import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { Wallet, CheckCircle2, XCircle, QrCode, X, Copy,  ExternalLink, Check, ArrowLeftRight, UserPlus, PiggyBank, Loader2, List, SendHorizontal, Plus } from 'lucide-react';
import { QRCodeSVG } from 'qrcode.react';


const Card = ({ children, className = '' }) => (
  <div className={`bg-white rounded-lg shadow-md ${className}`}>
    {children}
  </div>
);

const CardHeader = ({ children }) => (
  <div className="px-6 py-4 border-b border-gray-200">
    {children}
  </div>
);

const CardTitle = ({ children }) => (
  <h3 className="text-lg font-semibold text-gray-900">
    {children}
  </h3>
);

const CardContent = ({ children, className = '' }) => (
  <div className={`px-6 py-4 ${className}`}>
    {children}
  </div>
);

// Balance Component
const API_BASE_URL = 'http://localhost:8000'; // Match your FastAPI server


const AddUSDBalanceButton = ({ onSelect }) => {
  return (
    <button
      onClick={() => onSelect('wallets')}
      className="w-full inline-flex items-center justify-center px-4 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
    >
      <Plus className="w-5 h-5 mr-2" />
      Add USD Balance
    </button>
  );
};

const TransferButton = ({ onSelect }) => {
  return (
    <button
      onClick={() => onSelect('transfer')}
      className="w-full inline-flex items-center justify-center px-4 py-3 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors"
    >
      <ArrowLeftRight className="w-5 h-5 mr-2" />
      Transfer USD to INR
    </button>
  );
};

const BalanceContent = ({ onSelect }) => {
  const [balanceData, setBalanceData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchBalance = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');

      if (!token) {
        throw new Error('Authentication token not found. Please login again.');
      }

      const response = await fetch(`${API_BASE_URL}/api/check_balance`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        credentials: 'include',
        mode: 'cors',
      });

      if (response.status === 401) {
        throw new Error('Session expired. Please login again.');
      }

      if (response.status === 403) {
        throw new Error('Access denied. Please check your permissions.');
      }

      if (!response.ok) {
        throw new Error(`Server error: ${response.status}`);
      }

      const data = await response.json();
      setBalanceData(data.data);
      setError(null);
    } catch (err) {
      console.error('Balance fetch error:', err);
      setError(err.message);
      setBalanceData(null);

      if (err.message.includes('login') || err.message.includes('token') || err.message.includes('session')) {
        window.location.href = '/login';
        return;
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBalance();
  }, []);

  if (loading) {
    return (
      <div className="p-6 flex items-center justify-center min-h-[400px]">
        <div className="text-center">
          <Loader2 className="w-8 h-8 animate-spin text-blue-600 mx-auto mb-2" />
          <p className="text-sm text-gray-500">Loading balance...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6">
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded relative">
          <h3 className="font-semibold mb-1">Error Loading Balance</h3>
          <p className="text-sm">{error}</p>
          {!error.includes('login') && !error.includes('token') && !error.includes('session') && (
            <button
              onClick={fetchBalance}
              className="mt-2 text-sm bg-red-100 hover:bg-red-200 text-red-800 px-3 py-1 rounded inline-flex items-center"
            >
              <Loader2 className="w-4 h-4 mr-1" />
              Retry
            </button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="p-6 space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-semibold">Current Balance</h2>
        <button
          onClick={fetchBalance}
          className="text-sm text-gray-500 hover:text-gray-700 flex items-center gap-1"
        >
          <Loader2 className="w-4 h-4" />
          Refresh
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-4">
          <Card>
            <CardHeader>
              <CardTitle>USD Balance</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-4xl font-bold text-blue-600">
                ${Number(balanceData?.total_amount || 0).toFixed(2)}
              </p>
            </CardContent>
          </Card>
          <AddUSDBalanceButton onSelect={onSelect} />
        </div>

        <div className="space-y-4">
          <Card>
            <CardHeader>
              <CardTitle>INR Balance</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-4xl font-bold text-green-600">
                ₹{Number(balanceData?.balances?.inr || 0).toFixed(2)}
              </p>
            </CardContent>
          </Card>
          <TransferButton onSelect={onSelect} />
        </div>
      </div>
    </div>
  );
};


const WalletsContent = () => {
  const [addresses, setAddresses] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [copiedAddress, setCopiedAddress] = useState(null);
  const [showQR, setShowQR] = useState(null);

  const fetchAddresses = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');

      if (!token) {
        throw new Error('Authentication token not found. Please login again.');
      }

      const response = await fetch(`${API_BASE_URL}/api/get_addresses`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        credentials: 'include',
        mode: 'cors',
      });

      if (response.status === 401) {
        throw new Error('Session expired. Please login again.');
      }

      if (response.status === 403) {
        throw new Error('Access denied. Please check your permissions.');
      }

      if (!response.ok) {
        throw new Error(`Server error: ${response.status}`);
      }

      const data = await response.json();
      setAddresses(data.data);
      setError(null);
    } catch (err) {
      console.error('Address fetch error:', err);
      setError(err.message);
      setAddresses(null);

      if (err.message.includes('login') || err.message.includes('token') || err.message.includes('session')) {
        window.location.href = '/login';
        return;
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAddresses();
  }, []);

  const copyToClipboard = async (address, type) => {
    try {
      await navigator.clipboard.writeText(address);
      setCopiedAddress(type);
      setTimeout(() => setCopiedAddress(null), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const getExplorerLink = (address, type) => {
    if (type === 'eth') {
      return `https://etherscan.io/address/${address}`;
    } else if (type === 'tron') {
      return `https://tronscan.org/#/address/${address}`;
    }
    return '#';
  };

  if (loading) {
    return (
      <div className="p-6 flex items-center justify-center min-h-[400px]">
        <div className="text-center">
          <Loader2 className="w-8 h-8 animate-spin text-blue-600 mx-auto mb-2" />
          <p className="text-sm text-gray-500">Loading addresses...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6">
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded relative">
          <h3 className="font-semibold mb-1">Error Loading Addresses</h3>
          <p className="text-sm">{error}</p>
          {!error.includes('login') && !error.includes('token') && !error.includes('session') && (
            <button
              onClick={fetchAddresses}
              className="mt-2 text-sm bg-red-100 hover:bg-red-200 text-red-800 px-3 py-1 rounded inline-flex items-center"
            >
              <Loader2 className="w-4 h-4 mr-1" />
              Retry
            </button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="p-6 space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-semibold">Deposit Addresses</h2>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* ETH Wallet Card */}
        {addresses?.eth_address && (
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <img src="/eth-logo.svg" alt="ETH" className="w-6 h-6" />
                ETH Network Address
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="bg-gray-50 p-4 rounded-lg">
                <p className="font-mono text-sm break-all">{addresses.eth_address}</p>
                <div className="mt-4 flex gap-2">
                  <button
                    onClick={() => copyToClipboard(addresses.eth_address, 'eth')}
                    className="text-sm px-3 py-1.5 bg-white border rounded-md hover:bg-gray-50 flex items-center gap-1"
                  >
                    {copiedAddress === 'eth' ? (
                      <Check className="w-4 h-4 text-green-500" />
                    ) : (
                      <Copy className="w-4 h-4" />
                    )}
                    {copiedAddress === 'eth' ? 'Copied!' : 'Copy'}
                  </button>
                  <button
                    onClick={() => setShowQR('eth')}
                    className="text-sm px-3 py-1.5 bg-white border rounded-md hover:bg-gray-50 flex items-center gap-1"
                  >
                    <QrCode className="w-4 h-4" />
                    QR Code
                  </button>
                  <a
                    href={getExplorerLink(addresses.eth_address, 'eth')}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm px-3 py-1.5 bg-white border rounded-md hover:bg-gray-50 flex items-center gap-1"
                  >
                    <ExternalLink className="w-4 h-4" />
                    View
                  </a>
                </div>
              </div>
            </CardContent>
          </Card>
        )}

        {/* TRON Wallet Card */}
        {addresses?.tron_address && (
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <img src="/tron-logo.svg" alt="TRON" className="w-6 h-6" />
                TRON Network Address
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="bg-gray-50 p-4 rounded-lg">
                <p className="font-mono text-sm break-all">{addresses.tron_address}</p>
                <div className="mt-4 flex gap-2">
                  <button
                    onClick={() => copyToClipboard(addresses.tron_address, 'tron')}
                    className="text-sm px-3 py-1.5 bg-white border rounded-md hover:bg-gray-50 flex items-center gap-1"
                  >
                    {copiedAddress === 'tron' ? (
                      <Check className="w-4 h-4 text-green-500" />
                    ) : (
                      <Copy className="w-4 h-4" />
                    )}
                    {copiedAddress === 'tron' ? 'Copied!' : 'Copy'}
                  </button>
                  <button
                    onClick={() => setShowQR('tron')}
                    className="text-sm px-3 py-1.5 bg-white border rounded-md hover:bg-gray-50 flex items-center gap-1"
                  >
                    <QrCode className="w-4 h-4" />
                    QR Code
                  </button>
                  <a
                    href={getExplorerLink(addresses.tron_address, 'tron')}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm px-3 py-1.5 bg-white border rounded-md hover:bg-gray-50 flex items-center gap-1"
                  >
                    <ExternalLink className="w-4 h-4" />
                    View
                  </a>
                </div>
              </div>
            </CardContent>
          </Card>
        )}
      </div>

      {/* Warning Message */}
      <div className="bg-yellow-50 border border-yellow-200 text-yellow-800 px-4 py-3 rounded">
        <p className="text-sm">
          <strong>Important:</strong> Only send USDC to the ETH address and USDT to either address.
          Sending other tokens may result in permanent loss of funds.
        </p>
      </div>

      {/* QR Code Modal */}
      {showQR && addresses && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-sm w-full mx-4">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">
                {showQR === 'eth' ? 'ETH' : 'TRON'} Address QR Code
              </h3>
              <button
                onClick={() => setShowQR(null)}
                className="text-gray-500 hover:text-gray-700"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
            <div className="bg-white p-4 rounded-lg flex justify-center">
              <QRCodeSVG
                value={showQR === 'eth' ? addresses.eth_address : addresses.tron_address}
                size={200}
                level="H"
                includeMargin={true}
                className="w-full max-w-[200px]"
              />
            </div>
            <p className="mt-4 text-sm text-gray-600 break-all font-mono">
              {showQR === 'eth' ? addresses.eth_address : addresses.tron_address}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};


// Transfer Component
const TransferContent = () => {
  const [amount, setAmount] = useState('');
  const [conversionRate] = useState(85);
  const [estimatedInr, setEstimatedInr] = useState('');
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);

  const handleAmountChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue === '' || /^\d*\.?\d{0,2}$/.test(inputValue)) {
      if (inputValue.length > 1 && inputValue[0] === '0' && inputValue[1] !== '.') {
        return;
      }
      setAmount(inputValue);
    }
  };

  const handleAmountBlur = () => {
    if (amount === '') return;
    if (amount.endsWith('.')) {
      setAmount(amount.slice(0, -1));
      return;
    }
    const numValue = parseFloat(amount);
    if (!isNaN(numValue)) {
      setAmount(numValue.toFixed(2));
    }
  };

  useEffect(() => {
    if (amount === '') {
      setEstimatedInr('');
      return;
    }
    const usdAmount = parseFloat(amount);
    if (!isNaN(usdAmount)) {
      const inrAmount = usdAmount * conversionRate;
      setEstimatedInr(inrAmount.toLocaleString('en-IN', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      }));
    }
  }, [amount, conversionRate]);

  // Clear alert after 5 seconds
  useEffect(() => {
    if (alert) {
      const timer = setTimeout(() => {
        setAlert(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [alert]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!amount || parseFloat(amount) === 0) return;

    try {
      setLoading(true);
      setAlert(null);

      const token = localStorage.getItem('token');

      if (!token) {
        throw new Error('Authentication token not found. Please login again.');
      }

      const response = await fetch(`${API_BASE_URL}/api/transactions/`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          usd_amount: parseFloat(amount),
          transaction_rate: conversionRate
        }),
        credentials: 'include',
        mode: 'cors',
      });

      if (response.status === 401) {
        throw new Error('Session expired. Please login again.');
      }

      if (response.status === 403) {
        throw new Error('Access denied. Please check your permissions.');
      }

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Failed to process transaction');
      }

      //const data = await response.json();

      setAlert({
        type: 'success',
        message: `Successfully transferred $${amount} to INR at rate ${conversionRate}`
      });

      // Reset form
      setAmount('');
      setEstimatedInr('');

    } catch (err) {
      console.error('Transaction error:', err);

      setAlert({
        type: 'error',
        message: err.message
      });

      if (err.message.includes('login') || err.message.includes('token') || err.message.includes('session')) {
        window.location.href = '/login';
        return;
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-6 space-y-4">
      <h2 className="text-2xl font-semibold mb-6">Transfer USD to INR</h2>

      {alert && (
        <div className={`p-4 rounded-md flex items-center ${
          alert.type === 'error'
            ? 'bg-red-50 border border-red-200 text-red-700'
            : 'bg-green-50 border border-green-200 text-green-700'
        }`}>
          {alert.type === 'error'
            ? <XCircle className="w-5 h-5 mr-2" />
            : <CheckCircle2 className="w-5 h-5 mr-2" />
          }
          <p>{alert.message}</p>
        </div>
      )}

      <Card>
        <CardContent>
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Amount (USD)
              </label>
              <input
                type="text"
                value={amount}
                onChange={handleAmountChange}
                onBlur={handleAmountBlur}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                placeholder="Enter amount in USD"
                disabled={loading}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Conversion Rate (1 USD = INR)
              </label>
              <input
                type="text"
                className="w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md"
                value={conversionRate.toFixed(2)}
                disabled
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Estimated INR Amount
              </label>
              <input
                type="text"
                className="w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md"
                value={estimatedInr ? `₹${estimatedInr}` : ''}
                disabled
                placeholder="₹0.00"
              />
            </div>

            <button
              type="submit"
              className="w-full px-4 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors disabled:bg-blue-400 disabled:cursor-not-allowed"
              disabled={loading || !amount || parseFloat(amount) === 0}
            >
              {loading ? (
                <span className="flex items-center justify-center">
                  <Loader2 className="w-5 h-5 animate-spin mr-2" />
                  Processing...
                </span>
              ) : (
                'Transfer Now'
              )}
            </button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};


const AddSenderContent = () => {
  const [formData, setFormData] = useState({
  beneficiary_name: '',  // Changed from sender_name
  dob: '',
  current_address: '',
  nationality: '',
  kyc_type: '',
  kyc_number: '',
  kyc_document: null,
});

  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const nationalities = [
    'Indian', 'American', 'British', 'Canadian', 'Australian',
    'Chinese', 'Japanese', 'French', 'German', 'Italian'
  ];

  const kycTypes = ['Passport', 'Aadhar', 'PAN Card'];

  const validateForm = () => {
    if (!formData.beneficiary_name.trim()) {
      setError('Sender name is required');
      return false;
    }

    if (!formData.dob) {
      setError('Date of birth is required');
      return false;
    }

    if (!formData.current_address.trim()) {
      setError('Current address is required');
      return false;
    }

    if (!formData.nationality) {
      setError('Nationality is required');
      return false;
    }

    if (!formData.kyc_type) {
      setError('KYC type is required');
      return false;
    }

    if (!formData.kyc_number.trim()) {
      setError('KYC number is required');
      return false;
    }

    return true;
  };

  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
  const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png', 'application/pdf'];

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        setError('File size should not exceed 5MB');
        e.target.value = '';
        return;
      }

      if (!ALLOWED_FILE_TYPES.includes(file.type)) {
        setError('Only JPG, PNG, and PDF files are allowed');
        e.target.value = '';
        return;
      }

      setFormData(prev => ({
        ...prev,
        kyc_document: file
      }));
      setError('');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);

    try {
      const formDataToSend = new FormData();

      // Format and append all form fields
      const fieldsToAppend = {
  'beneficiary_name': formData.beneficiary_name.trim(),  // Changed from sender_name
  'dob': new Date(formData.dob).toISOString().split('T')[0],
  'current_address': formData.current_address.trim(),
  'nationality': formData.nationality,
  'kyc_type': formData.kyc_type,
  'kyc_number': formData.kyc_number.trim()
};

      Object.entries(fieldsToAppend).forEach(([key, value]) => {
        formDataToSend.append(key, value);
      });

      if (formData.kyc_document) {
        formDataToSend.append('kyc_document', formData.kyc_document);
      }

      const API_BASE_URL = process.env.NEXT_PUBLIC_API_URL || 'http://localhost:8000';
      const response = await fetch(`${API_BASE_URL}/api/beneficiaries/`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: formDataToSend,
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to add sender');
      }

      setFormData({
        beneficiary_name: '',
        dob: '',
        current_address: '',
        nationality: '',
        kyc_type: '',
        kyc_number: '',
        kyc_document: null,
      });

      const fileInput = document.querySelector('input[type="file"]');
      if (fileInput) {
        fileInput.value = '';
      }

      alert('Sender added successfully!');

    } catch (error) {
      setError(error.message || 'Failed to add sender. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="p-6">
      <h2 className="text-2xl font-semibold mb-6">Add Sender</h2>
      <Card>
        <CardContent className="pt-6">
          <form onSubmit={handleSubmit} className="space-y-6">
            {error && (
              <div className="p-4 mb-4 text-sm text-red-800 bg-red-100 rounded-lg">
                {error}
              </div>
            )}

            <div className="space-y-6">
              <h3 className="text-lg font-medium">Personal Information</h3>

              {/* Sender Name */}
              <div>
                <label htmlFor="beneficiary_name" className="block text-sm font-medium text-gray-700 mb-2">
                  Sender Name *
                </label>
                <input
  id="beneficiary_name"  // Changed from sender_name
  name="beneficiary_name"  // Changed from sender_name
  type="text"
  value={formData.beneficiary_name}  // Changed from sender_name
  onChange={handleInputChange}
  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
  placeholder="Enter full name"
/>
              </div>

              {/* Date of Birth */}
              <div>
                <label htmlFor="dob" className="block text-sm font-medium text-gray-700 mb-2">
                  Date of Birth *
                </label>
                <input
                  id="dob"
                  name="dob"
                  type="date"
                  value={formData.dob}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              {/* Current Address */}
              <div>
                <label htmlFor="current_address" className="block text-sm font-medium text-gray-700 mb-2">
                  Current Address *
                </label>
                <textarea
                  id="current_address"
                  name="current_address"
                  value={formData.current_address}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter current address"
                  rows={3}
                />
              </div>

              {/* Nationality */}
              <div>
                <label htmlFor="nationality" className="block text-sm font-medium text-gray-700 mb-2">
                  Nationality *
                </label>
                <select
                  id="nationality"
                  name="nationality"
                  value={formData.nationality}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value="">Select Nationality</option>
                  {nationalities.map((nationality) => (
                    <option key={nationality} value={nationality}>{nationality}</option>
                  ))}
                </select>
              </div>

              {/* KYC Type */}
              <div>
                <label htmlFor="kyc_type" className="block text-sm font-medium text-gray-700 mb-2">
                  KYC Document Type *
                </label>
                <select
                  id="kyc_type"
                  name="kyc_type"
                  value={formData.kyc_type}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value="">Select KYC Document Type</option>
                  {kycTypes.map((type) => (
                    <option key={type} value={type}>{type}</option>
                  ))}
                </select>
              </div>

              {/* KYC Number */}
              <div>
                <label htmlFor="kyc_number" className="block text-sm font-medium text-gray-700 mb-2">
                  KYC Number *
                </label>
                <input
                  id="kyc_number"
                  name="kyc_number"
                  type="text"
                  value={formData.kyc_number}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter KYC document number"
                />
              </div>

              {/* KYC Document Upload */}
              <div>
                <label htmlFor="kyc_document" className="block text-sm font-medium text-gray-700 mb-2">
                  KYC Document (JPG, PNG, or PDF, max 5MB)
                </label>
                <input
                  id="kyc_document"
                  type="file"
                  onChange={handleFileChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  accept=".pdf,.jpg,.jpeg,.png"
                />
              </div>
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className="w-full px-4 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors disabled:bg-blue-300 disabled:cursor-not-allowed"
            >
              {isSubmitting ? 'Adding Sender...' : 'Add Sender'}
            </button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};


const AddReceiverContent = () => {
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [selectedBeneficiaryId, setSelectedBeneficiaryId] = useState('');
  const [isLoadingBeneficiaries, setIsLoadingBeneficiaries] = useState(true);
  const [formData, setFormData] = useState({
    beneficiary_id: '',
    receiver_name: '',
    ifsc_code: '',
    account_number: '',
    upi_id: '',
  });

  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Fetch beneficiaries list when component mounts
  useEffect(() => {
    const fetchBeneficiaries = async () => {
      try {
        const API_BASE_URL = process.env.NEXT_PUBLIC_API_URL || 'http://localhost:8000';
        const response = await fetch(`${API_BASE_URL}/api/beneficiaries/kyc/`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error('Failed to fetch beneficiaries');
        }

        const data = await response.json();
        setBeneficiaries(data);
      } catch (error) {
        setError('Failed to load beneficiaries list. Please try again.');
      } finally {
        setIsLoadingBeneficiaries(false);
      }
    };

    fetchBeneficiaries();
  }, []);

  const handleBeneficiaryChange = (e) => {
    const beneficiaryId = e.target.value;
    setSelectedBeneficiaryId(beneficiaryId);
    setFormData(prev => ({
      ...prev,
      beneficiary_id: beneficiaryId
    }));
    setError('');
  };

  const validateForm = () => {
    if (!formData.beneficiary_id) {
      setError('Please select a sender');
      return false;
    }

    if (!formData.receiver_name.trim()) {
      setError('Receiver name is required');
      return false;
    }

    // Check payment information
    const hasAccountDetails = formData.ifsc_code.trim() && formData.account_number.trim();
    const hasUPI = formData.upi_id.trim();

    if (!hasAccountDetails && !hasUPI) {
      setError('Please provide either bank account details or UPI ID');
      return false;
    }

    // Validate IFSC code format if provided
    if (formData.ifsc_code && !/^[A-Z]{4}0[A-Z0-9]{6}$/.test(formData.ifsc_code)) {
      setError('Invalid IFSC code format');
      return false;
    }

    // Validate UPI ID format if provided
    if (formData.upi_id && !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9]+$/.test(formData.upi_id)) {
      setError('Invalid UPI ID format');
      return false;
    }

    return true;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);

    try {
      const API_BASE_URL = process.env.NEXT_PUBLIC_API_URL || 'http://localhost:8000';
      const response = await fetch(`${API_BASE_URL}/api/receivers/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(formData),
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to add receiver');
      }

      setFormData({
        beneficiary_id: selectedBeneficiaryId, // Keep the selected beneficiary
        receiver_name: '',
        ifsc_code: '',
        account_number: '',
        upi_id: '',
      });

      alert('Receiver added successfully!');

    } catch (error) {
      setError(error.message || 'Failed to add receiver. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="p-6">
      <h2 className="text-2xl font-semibold mb-6">Add Receiver</h2>
      <Card>
        <CardContent className="pt-6">
          <form onSubmit={handleSubmit} className="space-y-6">
            {error && (
              <div className="p-4 mb-4 text-sm text-red-800 bg-red-100 rounded-lg">
                {error}
              </div>
            )}

            {/* Beneficiary Selection */}
            <div>
              <label htmlFor="beneficiary_id" className="block text-sm font-medium text-gray-700 mb-2">
                Select Sender *
              </label>
              {isLoadingBeneficiaries ? (
                <div className="text-sm text-gray-500">Loading senders...</div>
              ) : (
                <select
                  id="beneficiary_id"
                  name="beneficiary_id"
                  value={selectedBeneficiaryId}
                  onChange={handleBeneficiaryChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value="">Select a sender</option>
                  {beneficiaries.map((beneficiary) => (
                    <option key={beneficiary.id} value={beneficiary.id}>
                      {beneficiary.beneficiary_name}
                    </option>
                  ))}
                </select>
              )}
            </div>

            {/* Show rest of the form only if beneficiary is selected */}
            {selectedBeneficiaryId && (
              <>
                {/* Receiver Name */}
                <div>
                  <label htmlFor="receiver_name" className="block text-sm font-medium text-gray-700 mb-2">
                    Receiver Name *
                  </label>
                  <input
                    id="receiver_name"
                    name="receiver_name"
                    type="text"
                    value={formData.receiver_name}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Enter receiver name"
                  />
                </div>

                {/* Payment Information Section */}
                <div className="space-y-6">
                  <div className="border-t pt-6">
                    <h3 className="text-lg font-medium mb-4">Payment Information</h3>
                    <p className="text-sm text-gray-500 mb-4">Fill either Bank Details or UPI ID</p>

                    {/* Bank Details */}
                    <div className="space-y-4">
                      <div>
                        <label htmlFor="ifsc_code" className="block text-sm font-medium text-gray-700 mb-2">
                          IFSC Code
                        </label>
                        <input
                          id="ifsc_code"
                          name="ifsc_code"
                          type="text"
                          value={formData.ifsc_code}
                          onChange={handleInputChange}
                          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                          placeholder="Enter IFSC Code"
                        />
                      </div>

                      <div>
                        <label htmlFor="account_number" className="block text-sm font-medium text-gray-700 mb-2">
                          Account Number
                        </label>
                        <input
                          id="account_number"
                          name="account_number"
                          type="text"
                          value={formData.account_number}
                          onChange={handleInputChange}
                          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                          placeholder="Enter Account Number"
                        />
                      </div>
                    </div>

                    {/* UPI ID */}
                    <div className="mt-4">
                      <label htmlFor="upi_id" className="block text-sm font-medium text-gray-700 mb-2">
                        UPI ID
                      </label>
                      <input
                        id="upi_id"
                        name="upi_id"
                        type="text"
                        value={formData.upi_id}
                        onChange={handleInputChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Enter UPI ID"
                      />
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="w-full px-4 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors disabled:bg-blue-300 disabled:cursor-not-allowed"
                >
                  {isSubmitting ? 'Adding Receiver...' : 'Add Receiver'}
                </button>
              </>
            )}
          </form>
        </CardContent>
      </Card>
    </div>
  );
};


// List User Accounts Component
const ListAccountsContent = () => {
  const [senders, setSenders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSenders = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Authentication token not found');
        }

        const API_BASE_URL = process.env.NEXT_PUBLIC_API_URL || 'http://localhost:8000';
        const response = await fetch(`${API_BASE_URL}/api/beneficiaries/`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
          },
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error('Failed to fetch senders');
        }

        const data = await response.json();
        setSenders(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSenders();
  }, []);

  if (loading) {
    return (
      <div className="p-6 flex justify-center items-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6">
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded">
          Error: {error}
        </div>
      </div>
    );
  }

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold">Senders</h2>
      </div>

      <Card>
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Name</th>
                <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">KYC Status</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {senders.map((sender) => (
                <tr key={sender.id}>
                  <td className="px-6 py-4 text-sm text-gray-900">{sender.beneficiary_name}</td>
                  <td className="px-6 py-4 text-sm">
                    <span className={`inline-flex px-2 py-1 rounded-full text-xs font-medium ${
                      sender.kyc === true || sender.kyc === 'verified'
                        ? 'bg-green-100 text-green-800'
                        : 'bg-yellow-100 text-yellow-800'
                    }`}>
                      {sender.kyc === true || sender.kyc === 'verified' ? 'Verified' : 'Pending'}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {senders.length === 0 && (
          <div className="text-center py-6 text-gray-500">
            No senders found. Click "Add New Sender" to add one.
          </div>
        )}
      </Card>
    </div>
  );
};


const ListReceiversContent = () => {
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [receivers, setReceivers] = useState([]);
  const [selectedBeneficiaryId, setSelectedBeneficiaryId] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isLoadingBeneficiaries, setIsLoadingBeneficiaries] = useState(true);

  // Fetch beneficiaries list on component mount
  useEffect(() => {
    const fetchBeneficiaries = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Authentication token not found');
        }

        const API_BASE_URL = process.env.NEXT_PUBLIC_API_URL || 'http://localhost:8000';
        const response = await fetch(`${API_BASE_URL}/api/beneficiaries/kyc/`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
          },
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error('Failed to fetch senders');
        }

        const data = await response.json();
        setBeneficiaries(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoadingBeneficiaries(false);
      }
    };

    fetchBeneficiaries();
  }, []);

  // Fetch receivers when beneficiary is selected
  useEffect(() => {
    const fetchReceivers = async () => {
      if (!selectedBeneficiaryId) {
        setReceivers([]);
        return;
      }

      setLoading(true);
      setError(null);

      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Authentication token not found');
        }

        const API_BASE_URL = process.env.NEXT_PUBLIC_API_URL || 'http://localhost:8000';
        const response = await fetch(`${API_BASE_URL}/api/receivers/${selectedBeneficiaryId}/`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
          },
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error('Failed to fetch receivers');
        }

        const data = await response.json();
        setReceivers(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchReceivers();
  }, [selectedBeneficiaryId]);

  const handleBeneficiaryChange = (e) => {
    setSelectedBeneficiaryId(e.target.value);
  };

  if (isLoadingBeneficiaries) {
    return (
      <div className="p-6 flex justify-center items-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold">Receivers List</h2>
      </div>

      <Card>
        <div className="p-6">
          {/* Beneficiary Selection Dropdown */}
          <div className="mb-6">
            <label htmlFor="beneficiary" className="block text-sm font-medium text-gray-700 mb-2">
              Select Sender
            </label>
            <select
              id="beneficiary"
              value={selectedBeneficiaryId}
              onChange={handleBeneficiaryChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Select a sender</option>
              {beneficiaries.map((beneficiary) => (
                <option key={beneficiary.id} value={beneficiary.id}>
                  {beneficiary.beneficiary_name}
                </option>
              ))}
            </select>
          </div>

          {/* Error Message */}
          {error && (
            <div className="mb-6 bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded">
              Error: {error}
            </div>
          )}

          {/* Receivers Table */}
          {selectedBeneficiaryId && (
            <div className="overflow-x-auto">
              {loading ? (
                <div className="flex justify-center items-center py-4">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
                </div>
              ) : (
                <>
                  <table className="w-full">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Receiver Name</th>
                        <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">IFSC Code</th>
                        <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Account Number</th>
                        <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">UPI ID</th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {receivers.map((receiver) => (
                        <tr key={receiver.id}>
                          <td className="px-6 py-4 text-sm text-gray-900">{receiver.receiver_name}</td>
                          <td className="px-6 py-4 text-sm text-gray-900">
                            {receiver.ifsc_code || '-'}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-900">
                            {receiver.account_number || '-'}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-900">
                            {receiver.upi_id || '-'}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  {receivers.length === 0 && (
                    <div className="text-center py-6 text-gray-500">
                      No receivers found for this beneficiary. Click "Add New Receiver" to add one.
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

// Send Money Component
const SendMoneyContent = () => {
  const [amount, setAmount] = useState('');
  const [note, setNote] = useState('');
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [receivers, setReceivers] = useState([]);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState('');
  const [selectedReceiver, setSelectedReceiver] = useState('');
  const [loading, setLoading] = useState(true);
  const [loadingReceivers, setLoadingReceivers] = useState(false);
  const [error, setError] = useState(null);
  const [sending, setSending] = useState(false);

  // Fetch beneficiaries list
  useEffect(() => {
    const fetchBeneficiaries = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Authentication token not found. Please login again.');
        }

        const API_BASE_URL = process.env.NEXT_PUBLIC_API_URL || 'http://localhost:8000';
        const response = await fetch(`${API_BASE_URL}/api/beneficiaries/kyc/`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
          },
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error('Failed to fetch senders');
        }

        const data = await response.json();
        setBeneficiaries(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBeneficiaries();
  }, []);

  // Fetch receivers when beneficiary is selected
  useEffect(() => {
    const fetchReceivers = async () => {
      if (!selectedBeneficiary) {
        setReceivers([]);
        return;
      }

      setLoadingReceivers(true);
      setError(null);

      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Authentication token not found');
        }

        const API_BASE_URL = process.env.NEXT_PUBLIC_API_URL || 'http://localhost:8000';
        const response = await fetch(`${API_BASE_URL}/api/receivers/${selectedBeneficiary}/`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
          },
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error('Failed to fetch receivers');
        }

        const data = await response.json();
        setReceivers(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoadingReceivers(false);
      }
    };

    fetchReceivers();
  }, [selectedBeneficiary]);

  const handleAmountChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue === '' || /^\d*\.?\d{0,2}$/.test(inputValue)) {
      if (inputValue.length > 1 && inputValue[0] === '0' && inputValue[1] !== '.') {
        return;
      }
      setAmount(inputValue);
    }
  };

  const handleAmountBlur = () => {
    if (amount === '') return;
    if (amount.endsWith('.')) {
      setAmount(amount.slice(0, -1));
      return;
    }
    const numValue = parseFloat(amount);
    if (!isNaN(numValue)) {
      setAmount(numValue.toFixed(2));
    }
  };

  const handleBeneficiaryChange = (e) => {
    setSelectedBeneficiary(e.target.value);
    setSelectedReceiver(''); // Reset receiver selection when beneficiary changes
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedBeneficiary || !selectedReceiver || !amount) {
      alert('Please select a sender, receiver and enter an amount');
      return;
    }

    const numAmount = parseFloat(amount);
    if (isNaN(numAmount) || numAmount <= 0) {
      alert('Please enter a valid amount');
      return;
    }

    try {
      setSending(true);
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Authentication token not found');
      }

      const API_BASE_URL = process.env.NEXT_PUBLIC_API_URL || 'http://localhost:8000';
      const response = await fetch(`${API_BASE_URL}/api/transfers/`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          beneficiary_id: parseInt(selectedBeneficiary),
          receiver_id: parseInt(selectedReceiver),
          inr_amount: numAmount,
          notes: note.trim() || null,
        }),
        credentials: 'include',
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.detail || 'Failed to send money');
      }

      // Reset form
      setAmount('');
      setNote('');
      setSelectedReceiver('');

      alert('Money sent successfully!');
    } catch (err) {
      alert(err.message);
    } finally {
      setSending(false);
    }
  };

  return (
    <div className="p-6">
      <h2 className="text-2xl font-semibold mb-6">Send Money</h2>
      {loading ? (
        <div className="flex justify-center items-center py-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
        </div>
      ) : error ? (
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded mb-6">
          {error}
        </div>
      ) : (
        <Card>
          <CardContent>
            {beneficiaries.length === 0 ? (
              <div className="text-center py-6 text-gray-500">
                No senders found. Please add a sender first.
              </div>
            ) : (
              <form className="space-y-6" onSubmit={handleSubmit}>
                {/* Beneficiary Selection */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Select Sender
                  </label>
                  <select
                    value={selectedBeneficiary}
                    onChange={handleBeneficiaryChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                  >
                    <option value="">Select beneficiary</option>
                    {beneficiaries.map(beneficiary => (
                      <option key={beneficiary.id} value={beneficiary.id}>
                        {beneficiary.beneficiary_name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Receiver Selection */}
                {selectedBeneficiary && (
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Select Receiver
                    </label>
                    {loadingReceivers ? (
                      <div className="flex justify-center py-2">
                        <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-gray-900"></div>
                      </div>
                    ) : (
                      <select
                        value={selectedReceiver}
                        onChange={(e) => setSelectedReceiver(e.target.value)}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        required
                      >
                        <option value="">Select receiver</option>
                        {receivers.map(receiver => (
                          <option key={receiver.id} value={receiver.id}>
                            {receiver.receiver_name}
                            {receiver.account_number ? ` - A/C: ${receiver.account_number}` : ''}
                            {receiver.upi_id ? ` - UPI: ${receiver.upi_id}` : ''}
                          </option>
                        ))}
                      </select>
                    )}
                    {receivers.length === 0 && !loadingReceivers && (
                      <p className="mt-2 text-sm text-gray-500">No receivers found for this beneficiary</p>
                    )}
                  </div>
                )}

                {/* Amount Input */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Amount (INR)
                  </label>
                  <input
                    type="text"
                    value={amount}
                    onChange={handleAmountChange}
                    onBlur={handleAmountBlur}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&:+-webkit-inner-spin-button]:appearance-none"
                    placeholder="Enter amount in INR"
                    required
                  />
                </div>

                {/* Note Input */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Note (Optional)
                  </label>
                  <textarea
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    rows={3}
                    placeholder="Add a note"
                  />
                </div>

                {/* Submit Button */}
                <button
                  type="submit"
                  disabled={sending || !selectedBeneficiary || !selectedReceiver || !amount}
                  className="w-full px-4 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors disabled:bg-blue-300 disabled:cursor-not-allowed"
                >
                  {sending ? 'Sending...' : 'Send Money'}
                </button>
              </form>
            )}
          </CardContent>
        </Card>
      )}
    </div>
  );
};

const Dashboard = () => {
  const { user, logout } = useAuth();
  const [selectedOption, setSelectedOption] = useState('balance');

  useEffect(() => {
    console.log('Current user:', user);
    console.log('KYB status:', user?.kyb);
    console.log('KYB type:', typeof user?.kyb);
  }, [user]);

  const menuItems = [
    { id: 'balance', label: 'Check Balance', icon: PiggyBank },
    { id: 'wallets', label: 'Add USD Balance', icon: Wallet },
    { id: 'transfer', label: 'Transfer USD to INR', icon: ArrowLeftRight },
    { id: 'add-sender-account', label: 'Add Sender Account', icon: UserPlus },
    { id: 'list-accounts', label: 'List Sender Accounts', icon: List },
    { id: 'add-receiver-account', label: 'Add Receiver Account', icon: UserPlus },
    { id: 'list-receiver-accounts', label: 'List Receiver Accounts', icon: List },
    { id: 'send-money', label: 'Send Money', icon: SendHorizontal },
  ];

 const renderContent = () => {
  switch (selectedOption) {
    case 'balance':
      return <BalanceContent onSelect={setSelectedOption} />;
    case 'wallets':
      return <WalletsContent />;
    case 'transfer':
      return <TransferContent />;
    case 'add-sender-account':
      return <AddSenderContent />;
    case 'add-receiver-account':
      return <AddReceiverContent />;
    case 'list-accounts':
      return <ListAccountsContent />;
    case 'list-receiver-accounts':
      return <ListReceiversContent />;
    case 'send-money':
      return <SendMoneyContent />;
    default:
      return (
        <div className="p-6">
          <h2 className="text-2xl font-semibold mb-4">
            {menuItems.find(item => item.id === selectedOption)?.label}
          </h2>
          <div className="bg-white p-6 rounded-lg shadow">
            <p className="text-gray-600">This feature is under development...</p>
          </div>
        </div>
      );
  }
};

  if (!Boolean(user?.kyb)) {
    return (
      <div className="min-h-screen bg-gray-50">
        <nav className="bg-white shadow">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex items-center">
                <h1 className="text-xl font-semibold">Dashboard</h1>
              </div>
              <div className="flex items-center space-x-4">
                <span className="text-gray-700">Hi, {user?.name || user?.email}</span>
                <button
                  onClick={logout}
                  className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
                >
                  Logout
                </button>
              </div>
            </div>
          </div>
        </nav>
        <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <div className="px-4 py-6 sm:px-0">
            <div className="border-4 border-dashed border-gray-200 rounded-lg h-96 p-4">
              <div className="text-center">
                <div className="inline-flex items-center px-4 py-2 mb-4 bg-yellow-100 text-yellow-800 rounded-md">
                  <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  Verification in Progress
                </div>
                <h2 className="text-2xl font-semibold text-gray-800">KYB Verification Pending</h2>
                <p className="mt-2 text-gray-600">Our Team will soon contact you for your KYB verification.</p>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <nav className="bg-white shadow">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <h1 className="text-xl font-semibold">Dashboard</h1>
            </div>
            <div className="flex items-center space-x-4">
              <span className="text-gray-700">Hi, {user?.name || user?.email}</span>
              <button
                onClick={logout}
                className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </nav>

      <div className="flex">
        {/* Sidebar */}
        <div className="w-64 bg-white shadow-lg min-h-screen">
          <nav className="mt-8">
            <div className="px-2 space-y-1">
              {menuItems.map(({ id, label, icon: Icon }) => (
                <button
                  key={id}
                  onClick={() => setSelectedOption(id)}
                  className={`w-full flex items-center px-4 py-3 text-sm font-medium rounded-md transition-colors ${
                    selectedOption === id
                      ? 'bg-blue-50 text-blue-700'
                      : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                  }`}
                >
                  <Icon className="mr-3 h-5 w-5" />
                  {label}
                </button>
              ))}
            </div>
          </nav>
        </div>

        {/* Main Content */}
        <div className="flex-1 bg-gray-50">
          <main className="py-6">
            {renderContent()}
          </main>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;