import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const logout = useCallback(() => {
    localStorage.removeItem('token');
    setUser(null);
  }, []); // Empty dependency array since it doesn't depend on any state or props

  const fetchUserProfile = useCallback(async (token) => {
    try {
      const response = await fetch('http://localhost:8000/api/me', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        const userData = await response.json();
        setUser(userData);
      } else {
        console.error('Failed to fetch user profile');
        logout();
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
      logout();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = async (formData) => {
    try {
      const response = await fetch('http://localhost:8000/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        credentials: 'include',
        body: formData
      });

      const data = await response.json();

      if (!response.ok) {
        // Handle FastAPI validation errors
        if (data.detail) {
          if (typeof data.detail === 'object' && data.detail.msg) {
            return { success: false, error: data.detail.msg };
          }
          return { success: false, error: data.detail };
        }
        return { success: false, error: 'Login failed' };
      }

      // Store the token
      localStorage.setItem('token', data.access_token);

      // Fetch complete user profile
      await fetchUserProfile(data.access_token);

      return { success: true, data };
    } catch (error) {
      return {
        success: false,
        error: error.message || 'Network error'
      };
    }
  };

  // Load user profile on initial mount if token exists
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      fetchUserProfile(token);
    }
  }, [fetchUserProfile]);

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};