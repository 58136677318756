import React, { useState } from 'react';
import { Coins, Globe, Shield, X } from 'lucide-react';
import AuthForm from './AuthForm';

const IntegratedLanding = () => {
  const [showAuth, setShowAuth] = useState(false);

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-50">
      {/* Hero Section - Reduced vertical spacing */}
      <div className="relative">
        <div className="relative px-4 sm:px-6 lg:px-8 pt-12 pb-8 sm:pt-16 sm:pb-12">
          <main className="max-w-7xl mx-auto">
            <div className="text-center">
              <h1 className="text-4xl sm:text-5xl lg:text-6xl tracking-tight font-extrabold text-blue-600 animate-slideDown">
                SuperStable
              </h1>
              <p className="mt-6 max-w-lg mx-auto text-lg sm:text-xl lg:text-2xl text-gray-600 lg:max-w-3xl animate-fadeIn">
                Revolutionizing payments across Emerging Nations and SEA using stablecoins
              </p>

              <div className="mt-10 animate-slideRight px-4 sm:px-0">
                <div className="max-w-sm mx-auto sm:max-w-md">
                  <p className="text-lg text-gray-600 mb-4">Ready to transform your payments?</p>
                  <button
                    onClick={() => setShowAuth(true)}
                    className="w-full sm:w-auto px-8 py-4 text-lg font-medium rounded-lg text-white bg-blue-600 hover:bg-blue-700 transition-all duration-200 hover:scale-[1.02] active:scale-[0.98] shadow-md"
                  >
                    Get Started
                  </button>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>

      {/* Features Section - Reduced top padding */}
      <div className="py-8 sm:py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 gap-y-8 sm:gap-12 md:grid-cols-2 lg:grid-cols-3 lg:gap-8">
            {/* Feature cards remain the same */}
            <div className="animate-fadeIn">
              <div className="h-full bg-white rounded-xl shadow-md hover:shadow-lg transition-shadow duration-300 px-6 py-8 relative">
                <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <div className="inline-flex items-center justify-center rounded-xl bg-blue-600 p-3 shadow-lg">
                    <Coins className="h-6 w-6 text-white" />
                  </div>
                </div>
                <div className="mt-6">
                  <h3 className="text-xl font-semibold text-gray-900 text-center mt-2">Stablecoin Payments</h3>
                  <p className="mt-4 text-gray-500 text-center leading-relaxed">
                    Fast, secure, and stable cryptocurrency transactions for modern businesses
                  </p>
                </div>
              </div>
            </div>

            <div className="animate-fadeIn" style={{ animationDelay: '0.2s' }}>
              <div className="h-full bg-white rounded-xl shadow-md hover:shadow-lg transition-shadow duration-300 px-6 py-8 relative">
                <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <div className="inline-flex items-center justify-center rounded-xl bg-blue-600 p-3 shadow-lg">
                    <Globe className="h-6 w-6 text-white" />
                  </div>
                </div>
                <div className="mt-6">
                  <h3 className="text-xl font-semibold text-gray-900 text-center mt-2">Cross-Border Solutions</h3>
                  <p className="mt-4 text-gray-500 text-center leading-relaxed">
                    Seamless payments across Emerging Nations and Southeast Asia without barriers
                  </p>
                </div>
              </div>
            </div>

            <div className="animate-fadeIn" style={{ animationDelay: '0.4s' }}>
              <div className="h-full bg-white rounded-xl shadow-md hover:shadow-lg transition-shadow duration-300 px-6 py-8 relative">
                <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <div className="inline-flex items-center justify-center rounded-xl bg-blue-600 p-3 shadow-lg">
                    <Shield className="h-6 w-6 text-white" />
                  </div>
                </div>
                <div className="mt-6">
                  <h3 className="text-xl font-semibold text-gray-900 text-center mt-2">Enterprise Security</h3>
                  <p className="mt-4 text-gray-500 text-center leading-relaxed">
                    Bank-grade security with blockchain transparency and reliability
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Auth Modal - More compact layout */}
      {showAuth && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-50 flex items-center justify-center p-4 sm:p-6">
          <div
            className="relative w-full max-w-xl transform transition-all animate-modalSlide bg-gradient-to-br from-blue-50 to-indigo-50 rounded-xl shadow-xl"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="absolute right-2 top-2 z-10">
              <button
                onClick={() => setShowAuth(false)}
                className="p-2 rounded-full hover:bg-gray-100 text-gray-500 hover:text-gray-700 transition-colors"
                aria-label="Close modal"
              >
                <X className="h-5 w-5" />
              </button>
            </div>
            {/* Apply more compact padding to AuthForm */}
            <div className="p-4 sm:p-6">
              <AuthForm />
            </div>
          </div>
        </div>
      )}

      <style jsx>{`
        @keyframes fadeIn {
          from { opacity: 0; }
          to { opacity: 1; }
        }

        @keyframes slideDown {
          from { transform: translateY(-20px); opacity: 0; }
          to { transform: translateY(0); opacity: 1; }
        }

        @keyframes slideRight {
          from { transform: translateX(-20px); opacity: 0; }
          to { transform: translateX(0); opacity: 1; }
        }

        @keyframes modalSlide {
          from { transform: scale(0.95); opacity: 0; }
          to { transform: scale(1); opacity: 1; }
        }

        .animate-fadeIn {
          animation: fadeIn 0.5s ease-out;
        }

        .animate-slideDown {
          animation: slideDown 0.5s ease-out;
        }

        .animate-slideRight {
          animation: slideRight 0.5s ease-out;
        }

        .animate-modalSlide {
          animation: modalSlide 0.3s ease-out;
        }
      `}</style>
    </div>
  );
};

export default IntegratedLanding;